const mainStyles = {
  button: {
    borderRadius: 25,
    minWidth: 185,
    padding: "10px 20px",
    textAlign: 'center',
    fontWeight: '500',
    textTransform: 'uppercase',
    '&:hover': {
      boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
    '&.disabled': {
      opacity: "0.2",
      pointerEvents: "none",
    },
  },
  primaryBtn: {
    background: '#0085BA',
    color: '#fff',
    '&:hover': {
      backgroundColor: "#005d82",
    },
  },
  inputFields: {
    backgroundColor: "#ECECEC",
    display: "flex",
    flexDirection: "column",
    borderRadius: 30,
    padding: "40px 60px 20px",
    '@media (max-width: 425px)': {
      padding: "30px 40px 20px",
    },
    "& .MuiFormControl-root": {
      marginBottom: 20,
      width: "100%",
      maxWidth: 500,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
    "& .email-field": {
      marginBottom: 5,
    },
  },
  iconBox: {
    position: "relative",
  },
  infoIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 10,
    right: "-35px",
  },
};

export default mainStyles;
