import React from "react";
import {Show, SimpleShowLayout, TextField} from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";

/*eslint-disable react/prop-types*/
const ShowBeacon = (props) => (
  <Show {...props} title={<MySimpleBreadcrumbs resource={props.resource} />}>
    <SimpleShowLayout>
      <TextField source="uid" />
      <TextField source="major" />
      <TextField source="minor" />
      <TextField source="macAddress" />
      <TextField source="tailNumber" />
      <TextField source="vehicleNumber" />
    </SimpleShowLayout>
  </Show>
);

export default ShowBeacon;
