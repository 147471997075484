import { withStyles } from "@material-ui/core";
import red from "@material-ui/core/colors/red.js";
import Button from "@material-ui/core/Button";

export default withStyles({
  root: {
    color: "white",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
})(Button);
