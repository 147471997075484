import React from "react";
import {Create, SimpleForm, TextInput, required, PasswordInput} from "react-admin";

const validateRequired = required();

const CreateDriver = (props) => {
  return (
    <Create {...props} title="resources.drivers.create">
      <SimpleForm redirect="list">
        <TextInput source="fullName" validate={validateRequired} />
        <TextInput source="personalNumber" validate={validateRequired} />
        <PasswordInput source="password" autoComplete="new-password" validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};

export default CreateDriver;
