import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import useHasCreate from "../../../../hooks/useHasCreate.js";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";

export const ListDrivers = (props) => {
  const hasCreate = useHasCreate();

  return (
    <List
      {...props}
      // exporter={false}
      bulkActionButtons={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="fullName" />
        <TextField source="personalNumber" />
        <EditButtons
          deleteConfirmTitleSource="fullName"
          hasView={!hasCreate}
          hasEdit={hasCreate}
          hasDelete={hasCreate}
        />
      </Datagrid>
    </List>
  );
};
