import React, { createContext, useContext, useState } from "react";
import { API_URL } from "../../Constants.js";
import { post } from "../../Utils/NetworkUtils.js";
import { useSnackbar } from "../Common/snackbarProvider.jsx";

/**
 * @type {React.Context<{signIn: FnAsyncLoginpasswordPermissions, signOut: Function, user: ?Permissions}>}
 */
const authContext = createContext({
  /** @type ?Permissions */
  user: null,
  /** @type FnAsyncLoginpasswordPermissions */
  signIn: () => {},
  /** @type Function */
  signOut: () => {},
});

export const useAuthContext = () => useContext(authContext);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const snackbar = useSnackbar();

  /** @type FnAsyncLoginpasswordPermissions */
  const signIn = async (lp) => {
    try {
      const response = await post(`${API_URL}/login`, lp);
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
        setUser(response.data);
        return response.data;
      } else {
        snackbar.error(`${response.error.code}: ${response.error.text}`);
      }
    } catch (e) {
      snackbar.error(e.toString());
    }
    return null;
  };

  const signOut = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  const value = {
    user,
    signIn,
    signOut,
  };
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export default AuthProvider;
