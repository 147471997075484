import React from "react";
import { Edit, SimpleForm, TextInput, required, PasswordInput, email, SelectInput } from "react-admin";
import MyStandardEditToolbar from "../../../../SharedComponents/MyStandardEditToolbar.jsx";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import { ROLE_CHOICES } from "../../../../Utils/Constants.js";

const validateRequired = required();
const validateEmail = email();

/*eslint-disable react/prop-types*/
const EditEmployee = (props) => {
  return (
    <Edit {...props} undoable={false} title={<MySimpleBreadcrumbs resource={props.resource} />}>
      <SimpleForm redirect="list" toolbar={<MyStandardEditToolbar nameField="fullName" />}>
        <SelectInput source="role" choices={ROLE_CHOICES.employees} validate={validateRequired} />
        <TextInput source="email" autoComplete="off" validate={[validateEmail, validateRequired]} />
        <PasswordInput source="password" autoComplete="new-password" />
        <TextInput source="fullName" validate={validateRequired} />
      </SimpleForm>
    </Edit>
  );
};

export default EditEmployee;
