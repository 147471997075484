import PropTypes from "prop-types";
import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const StyledCircularProgress = withStyles((theme) => ({
  root: {
    color: theme.palette.text.disabled,
  },
}))(CircularProgress);

const LoadingButton = ({ loading, startIcon, ...rest }) => {
  return (
    <Button
      {...rest}
      startIcon={loading ? <StyledCircularProgress size={18} thickness={4} /> : startIcon}
      disabled={loading}
    />
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  startIcon: PropTypes.any.isRequired,
};

LoadingButton.defaultProps = {
  loading: false,
};

export default LoadingButton;
