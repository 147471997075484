import React, { createContext, useContext, useState } from "react";
import useUnmount from "../../hooks/useUnmount.js";

/** @type {React.Context<RegistrationContext>} */
const registrationContext = createContext({
  registrationData: null,
  setRegistrationData: () => {},
  mergeWithData: () => {},
});

export const useRegistrationContext = () => useContext(registrationContext);

const RegistrationProvider = ({ children }) => {
  const [registrationData, setRegistrationData] = useState(
    /** @type RegistrationData */ JSON.parse(sessionStorage.getItem("registrationData")),
  );

  const saveRegistrationData = (data) => {
    sessionStorage.setItem("registrationData", JSON.stringify(data));
    setRegistrationData(data);
  };

  const mergeWithData = (data) => {
    const newData = {
      ...registrationData,
      ...data,
    };
    saveRegistrationData(newData);
    return newData;
  };

  useUnmount(() => {
    sessionStorage.removeItem("registrationData");
  });

  /** @type RegistrationContext */
  const value = {
    registrationData,
    setRegistrationData: saveRegistrationData,
    mergeWithData,
  };

  return <registrationContext.Provider value={value}>{children}</registrationContext.Provider>;
};

export default RegistrationProvider;
