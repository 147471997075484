import PropTypes from "prop-types";
import React from "react";
import { useTranslate } from "react-admin";

const fieldsToUse = ["name", "fullName", "vehicleNumber", "routeName"];

const MySimpleBreadcrumbs = ({ resource, ...rest }) => {
  const { record } = rest;
  const t = useTranslate();

  let instanceName;

  for (let i = 0; i < fieldsToUse.length; i++) {
    const field = fieldsToUse[i];
    const recordElement = record[field];
    if (recordElement) {
      instanceName = recordElement;
      break;
    }
  }

  if (!instanceName) {
    const part1 = t(`resources.${resource}.name`, { smart_count: 1 });
    instanceName = `${part1} #${record.id}`;
  }

  const text = t(`resources.${resource}.name`, { smart_count: 2 }) + ` / ${instanceName}`;
  document.title = `${t("common.appName")} - ${text}`;

  return <span>{text}</span>;
};

MySimpleBreadcrumbs.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default MySimpleBreadcrumbs;
