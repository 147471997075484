import React from "react";
import PropTypes from "prop-types";
import { DeleteWithConfirmButton, SaveButton, Toolbar, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  myToolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
});

/* eslint-disable react/prop-types */
const MyStandardEditToolbar = ({ nameField, customDeleteConfirmText, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();

  const deleteConfirmTitle = customDeleteConfirmText
    ? customDeleteConfirmText(rest.record)
    : `${t("ra.action.delete")} ${rest.record[nameField]}?`;

  return (
    <Toolbar {...rest} className={classes.myToolbar}>
      <SaveButton disabled={rest.pristine} />
      <DeleteWithConfirmButton size="medium" confirmTitle={deleteConfirmTitle} />
    </Toolbar>
  );
};

MyStandardEditToolbar.propTypes = {
  nameField: PropTypes.string,
  customDeleteConfirmText: PropTypes.func,
};

export default MyStandardEditToolbar;
