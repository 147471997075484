import React from "react";
import { MenuItemLink, getResources, useTranslate } from "react-admin";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import MenuItemAdminRequests from "./MenuItemAdminRequests.jsx";

const MyMenu = () => {
  const resources = useSelector(getResources);
  const translate = useTranslate();

  return (
    <Box marginTop="10px">
      {resources.map((resource) => {
        if (!resource.icon) {
          return null;
        }
        if (resource.name === "carrier-requests") {
          return (
            <MenuItemAdminRequests
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={translate(`resources.${resource.name}.name`, { smart_count: 2 })}
              leftIcon={<resource.icon />}
            />
          );
        } else {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={translate(`resources.${resource.name}.name`, { smart_count: 2 })}
              leftIcon={<resource.icon />}
            />
          );
        }
      })}
    </Box>
  );
};

export default MyMenu;
