import React from "react";
import * as PropTypes from "prop-types";
import { TextField, useTranslate } from "react-admin";
import lodash from "lodash";

const TextFieldWithNull = (props) => {
  const { source, record, textNull, ...rest } = props;
  const translate = useTranslate();
  const newRecord = {};

  const value = lodash.get(record, source);
  if (!value && textNull) {
    lodash.set(newRecord, source, translate(textNull, { _: textNull }));
  } else {
    lodash.set(newRecord, source, value);
  }

  return <TextField {...rest} source={source} record={newRecord} />;
};

TextFieldWithNull.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  textNull: PropTypes.string,
};

TextFieldWithNull.defaultProps = {
  addLabel: true,
};

export default TextFieldWithNull;
