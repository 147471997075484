import React from "react";
import Button from "@material-ui/core/Button";

/*eslint-disable unused-imports/no-unused-vars*/
/*eslint-disable react/prop-types*/
const ToolbarButton = ({
  basePath,
  children,
  handleSubmit,
  handleSubmitWithRedirect,
  onSave,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  undoable,
  ...rest
}) => <Button {...rest}>{children}</Button>;

export default ToolbarButton;
