import PropTypes from "prop-types";
import React, { useCallback } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles, MuiThemeProvider } from "@material-ui/core";
import { useLocale, useTranslate } from "react-admin";
import { useField, useFormState } from "react-final-form";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { useTheme } from "@material-ui/core/styles";
import { localeMap } from "../Providers/i18n/dateLocales.js";
import { overrideTheme } from "../Utils/theming.js";

const pickerThemeOverrides = {
  MuiInputBase: {
    root: {
      maxHeight: 48,
      marginBottom: 8,
      paddingRight: 0,
    },
  },
  MuiInputLabel: {
    shrink: {
      transform: "translate(12px, 5px) scale(0.75) !important",
    },
    filled: {
      transform: "translate(12px, 15px) scale(1)",
    },
  },
};

const useStyles = makeStyles({
  pickerRoot: {
    "& input": {
      cursor: "pointer",
    },
  },
});

const MaterialDateTimeInput = ({ source, resource }) => {
  const theme = useTheme();
  const classes = useStyles();
  const locale = useLocale();
  const t = useTranslate();
  const { input } = useField(source);
  const formState = useFormState();

  const handleButtonClick = useCallback(
    (event) => {
      event.stopPropagation();
      input.onChange(null);
    },
    [input],
  );

  const pickerTheme = overrideTheme(theme, pickerThemeOverrides);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <MuiThemeProvider theme={pickerTheme}>
        <DatePicker
          variant="inline"
          className={classes.pickerRoot}
          label={t(`resources.${resource}.fields.${source}`)}
          format="dd.MM.yyyy"
          inputVariant="filled"
          ampm={false}
          value={formState.values[source] || null} //null is needed for empty input field as default value
          onChange={input.onChange}
          InputProps={
            formState.values[source] && {
              endAdornment: (
                <IconButton onClick={handleButtonClick}>
                  <HighlightOffRoundedIcon />
                </IconButton>
              ),
            }
          }
        />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

MaterialDateTimeInput.propTypes = {
  resource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default MaterialDateTimeInput;
