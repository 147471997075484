import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";

/*eslint-disable react/prop-types*/
const ShowDriver = (props) => (
  <Show {...props} title={<MySimpleBreadcrumbs resource={props.resource} />}>
    <SimpleShowLayout>
      <TextField source="fullName" />
      <TextField source="personalNumber" />
    </SimpleShowLayout>
  </Show>
);

export default ShowDriver;
