import React from "react";
import RedButton from "./RedButton.jsx";

/*eslint-disable unused-imports/no-unused-vars*/
/*eslint-disable react/prop-types*/
const RedToolbarButton = ({
  basePath,
  children,
  handleSubmit,
  handleSubmitWithRedirect,
  onSave,
  invalid,
  pristine,
  saving,
  submitOnEnter,
  undoable,
  ...rest
}) => <RedButton {...rest}>{children}</RedButton>;

export default RedToolbarButton;
