import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const GoogleTranslatorProtectedRoute = ({ children, ...rest }) => {
  const [isGoogleTranslatorWorking, setGoogleTranslatorWorking] = useState(false);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const intervalHandle = setInterval(() => {
      const googleTtElement = document.getElementById("goog-gt-tt");
      setGoogleTranslatorWorking(!!googleTtElement);
    }, 1000);
    return () => clearInterval(intervalHandle);
  }, []);

  return (
    <Route
      {...rest}
      render={() =>
        !isGoogleTranslatorWorking ? (
          children
        ) : (
          <Box display="flex" width="100%" height="100vh" alignItems="center" justifyContent="center">
            <Typography variant="h2" style={{ textAlign: "center", width: "60%", fontWeight: 500 }}>
              Обнаружен Google Translator!
              <br />
              Выключите его и перезагрузите страницу, чтобы продолжить работу!
            </Typography>
          </Box>
        )
      }
    />
  );
};

export default GoogleTranslatorProtectedRoute;
