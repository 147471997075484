import PropTypes from "prop-types";
import React from "react";
import { Box } from "@material-ui/core";
import { DeleteWithConfirmButton, EditButton, useTranslate, ShowButton } from "react-admin";

const EditButtons = ({ hasDelete, hasEdit, hasView, deleteConfirmTitleSource, deleteConfirmTitle, ...rest }) => {
  const t = useTranslate();

  const title = deleteConfirmTitle
    ? deleteConfirmTitle(rest.record)
    : `${t("ra.action.delete")} ${rest.record[deleteConfirmTitleSource]}?`;

  return (
    <Box display="flex" justifyContent="flex-end">
      {hasView && <ShowButton {...rest} label="" />}
      {hasEdit && <EditButton {...rest} label="" />}
      {hasDelete && <DeleteWithConfirmButton {...rest} label="" confirmTitle={title} />}
    </Box>
  );
};

EditButtons.propTypes = {
  deleteConfirmTitle: PropTypes.func,
  deleteConfirmTitleSource: PropTypes.string,
  hasDelete: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasView: PropTypes.bool,
  record: PropTypes.any,
};

EditButtons.defaultProps = {
  hasDelete: true,
  hasEdit: true,
  hasView: false,
};

export default EditButtons;
