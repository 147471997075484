import PropTypes from "prop-types";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MySnackbar = ({ isOpen, close, severity, message }) => {
  return (
    <Snackbar open={isOpen} onClose={close} autoHideDuration={6000}>
      <Alert onClose={close} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

MySnackbar.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string,
  severity: PropTypes.string,
};

export default MySnackbar;
