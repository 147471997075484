import PropTypes from "prop-types";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  actionsRoot: {
    justifyContent: "center",
  },
}));

/**
 * @param {Boolean} open
 * @param {Function} handleClose
 * @param {String} title
 * @param {String} message
 * @returns {*}
 * @constructor
 */
const Alert = ({ open, handleClose, title, message }) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth="xs" disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
      {title && <DialogTitle>{"Use Google's location service?"}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionsRoot}>
        <Button onClick={handleClose} color="primary" autoFocus variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Alert.propTypes = {
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default Alert;
