import PropTypes from "prop-types";
import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles({
  roleTextInput: {
    margin: "0 0 22px 0",
  },
});

/*eslint-disable react/prop-types*/
/**
 * @param {Admin} record
 * @param {String} basePath - to stop propagation this prop to DOM
 * @param {Object} Component
 * @param {Object} rest
 * @returns {*}
 * @constructor
 */
//eslint-disable-next-line unused-imports/no-unused-vars
const RoleTextInputReadonly = ({ record, basePath, Component, ...rest }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const roleText = translate(`roles.${record.role}`);

  return (
    <Component
      {...rest}
      className={classes.roleTextInput}
      defaultValue={roleText}
      disabled
      label={translate("resources.carriers.fields.role")}
    />
  );
};

RoleTextInputReadonly.propTypes = {
  Component: PropTypes.any,
};

RoleTextInputReadonly.defaultProps = {
  Component: TextField,
};

export default RoleTextInputReadonly;
