import { format, parseISO } from "date-fns";
import { enUS } from "date-fns/locale";
import { localeMap } from "../Providers/i18n/dateLocales.js";

export const defaultDatePattern = "dd.MM.yyyy, HH:mm";

/**
 * @param {Date} date
 * @param datePattern
 * @param {String} locale
 * @returns {String}
 */
export function dateToString(date, datePattern = defaultDatePattern,  locale = "ru") {
  return format(date, datePattern, { locale: localeMap[locale] || enUS });
}

/**
 * @param {String} isoString
 * @param {String} locale
 * @returns {String}
 */
export function isoDateToDefaultDate(isoString, locale) {
  const date = parseISO(isoString);
  return dateToString(date, locale);
}

/**
 * @param {String} date
 * @param {Number} days
 * @returns {String}
 */
export function addWorkDays(date, days) {
  let dow = date.getDay();
  let daysToAdd = parseInt(days);
  if (dow === 0)
    daysToAdd++;
  if (dow + daysToAdd >= 6) {
    let remainingWorkDays = daysToAdd - (5 - dow);
    daysToAdd += 2;
    if (remainingWorkDays > 5) {
      //Add two days for each working week by calculating how many weeks are included
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
      //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
      if (remainingWorkDays % 5 === 0)
        daysToAdd -= 2;
    }
  }
  date.setDate(date.getDate() + daysToAdd);
  return date;
}
