import React from "react";
import * as PropTypes from "prop-types";
import { useLocale } from "react-admin";
import lodash from "lodash";
import TextFieldWithNull from "./TextFieldWithNull.jsx";
import { dateToString } from "../Utils/DateUtils.js";

const TextDateField = (props) => {
  const { record, source } = props;
  const locale = useLocale();
  const newRecord = {};

  const value = lodash.get(record, source);
  if (value) {
    lodash.set(newRecord, source, dateToString(new Date(value), "dd.MM.yyyy, HH:mm", locale));
  }

  return <TextFieldWithNull {...props} record={newRecord} />;
};

TextDateField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

TextDateField.defaultProps = {
  addLabel: true,
};

export default TextDateField;
