import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useGetList, MenuItemLink } from "react-admin";
import red from "@material-ui/core/colors/red.js";

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  badgeRoot: {
    position: "absolute",
    top: 6,
    right: 5,
    backgroundColor: red[500],
    color: "white",
    borderRadius: 15,
    padding: "2px 7px 0",
  },
});

const MenuItemAdminRequests = (props) => {
  const styles = useStyles();

  const requests = useGetList("carrier-requests", { page: 1, perPage: 0 }, { field: "id", order: "DESC" });

  const badgeValue = requests.total ? (requests.total > 99 ? "99+" : requests.total.toString()) : null;

  return (
    <div className={styles.root}>
      <MenuItemLink {...props} />
      {badgeValue && <div className={styles.badgeRoot}>{badgeValue}</div>}
    </div>
  );
};

export default MenuItemAdminRequests;
