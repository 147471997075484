import React from "react";
import { Create, SimpleForm, TextInput, required, email, PasswordInput, SelectInput } from "react-admin";
import { ROLE_CHOICES } from "../../../../Utils/Constants.js";

const validateRequired = required();
const validateEmail = email();

const CreateEmployee = (props) => {
  return (
    <Create {...props} title="resources.employees.create">
      <SimpleForm redirect="list">
        <SelectInput source="role" choices={ROLE_CHOICES.employees} validate={validateRequired} />
        <TextInput source="email" autoComplete="off" validate={[validateEmail, validateRequired]} />
        <PasswordInput source="password" autoComplete="new-password" validate={validateRequired} />
        <TextInput source="fullName" validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};

export default CreateEmployee;
