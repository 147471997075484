import React from "react";
import { Datagrid, List, TextField, EmailField, EditButton } from "react-admin";
import { Box } from "@material-ui/core";
import TextCreatedField from "../../../../SharedComponents/TextCreatedField.jsx";
import RoleTextField from "../../../../SharedComponents/RoleTextField.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";

const Buttons = (props) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <EditButton {...props} label="resources.carrier-requests.watch" icon={null} variant="contained" />
    </Box>
  );
};

export const ListCarrierRequests = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      hasCreate={false}
      perPage={10}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="index" />
        <RoleTextField source="role" />
        <TextField source="companyName" />
        <EmailField source="email" />
        <TextCreatedField />
        <Buttons label="" />
      </Datagrid>
    </List>
  );
};
