import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import EditButtons from "../../../../SharedComponents/EditButtons.jsx";
import MyPagination from "../../../../SharedComponents/MyPagination.jsx";
import ListTextFilter from "../../../../SharedComponents/ListTextFilter.jsx";

export const ListBeacon = (props) => {

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      hasCreate={false}
      perPage={25}
      pagination={<MyPagination />}
      filters={<ListTextFilter />}
    >
      <Datagrid>
        <TextField source="index" />
        <TextField source="uid" />
        <TextField source="major" />
        <TextField source="minor" />
        <TextField source="macAddress" />
        <TextField source="tailNumber" />
        <TextField source="vehicleNumber" />
        <EditButtons
          deleteConfirmTitleSource="uid"
          hasView={true}
          hasEdit={false}
          hasDelete={false}
        />
      </Datagrid>
    </List>
  );
};
