import { withStyles } from "@material-ui/core";
import orange from "@material-ui/core/colors/orange.js";
import Button from "@material-ui/core/Button";

export default withStyles({
  root: {
    color: "white",
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
  },
})(Button);
