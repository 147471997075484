import React from "react";
import "./App.css";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import AdminRoot from "./Modules/Admin/AdminRoot.jsx";
import StartPageRoot from "./Modules/Landing/StartPage/StartPageRoot.jsx";
import Login from "./Modules/Landing/Login/Login.jsx";
import RestorePassword from "./Modules/Landing/Login/RestorePassword.jsx";
import AuthProvider from "./Providers/Auth/authProvider.jsx";
import AuthProtectedRoute from "./Providers/Auth/authProtectedRoute.jsx";
import RegistrationProvider from "./Providers/Registration/registrationProvider.jsx";
import SnackbarProvider from "./Providers/Common/snackbarProvider.jsx";
import AlertProvider from "./Providers/Common/AlertProvider.jsx";
import GoogleTranslatorProtectedRoute from "./Providers/Common/googleTranslatorProtectedRoute.jsx";

function App() {
  return (
    <SnackbarProvider>
      <AlertProvider>
        <AuthProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={StartPageRoot} />
              <Route path="/registration">
                <RegistrationProvider>
                  <StartPageRoot />
                </RegistrationProvider>
              </Route>
              <AuthProtectedRoute path="/admin">
                <GoogleTranslatorProtectedRoute>
                  <AdminRoot />
                </GoogleTranslatorProtectedRoute>
              </AuthProtectedRoute>
              <Route path="/login" component={Login} />
              <Route path="/restore-password" component={RestorePassword} />
              <Redirect to="/" />
            </Switch>
          </Router>
        </AuthProvider>
      </AlertProvider>
    </SnackbarProvider>
  );
}

export default App;
