import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import Alert from "../../SharedComponents/Alert.jsx";

/**
 * @typedef {Object} AlertProviderContext
 * @property {FnMessageTitlePromise} alert
 */

/** @type {React.Context<AlertProviderContext>} */
const alertContext = createContext({
  /** @type {FnMessageTitlePromise} */
  alert: () => {},
});

export const useAlert = () => useContext(alertContext);

const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  /** @type {MutableRefObject<?Function>} */
  const promiseResolver = useRef(null);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setTitle("");
        setMessage("");
      }, 200);
    }
  }, [open]);

  const handleClose = useCallback(() => {
    setOpen(false);
    promiseResolver.current && promiseResolver.current();
    promiseResolver.current = null;
  }, []);

  const alert = useCallback((msg, title) => {
    setTitle(title);
    setMessage(msg);
    setOpen(true);
    return new Promise((resolve) => {
      promiseResolver.current = resolve;
    });
  }, []);

  /** @type {AlertProviderContext} */
  const value = {
    alert,
  };

  return (
    <alertContext.Provider value={value}>
      {children}
      <Alert open={open} handleClose={handleClose} title={title} message={message} />
    </alertContext.Provider>
  );
};

export default AlertProvider;
