import PropTypes from "prop-types";
import React from "react";
import {Edit, SimpleForm, TextInput, required, PasswordInput} from "react-admin";
import MySimpleBreadcrumbs from "../../../../SharedComponents/MySimpleBreadcrumbs.jsx";
import MyStandardEditToolbarWithList from "../../../../SharedComponents/MyStandardEditToolbarWithList.jsx";

const validateRequired = required();

/*eslint-disable react/prop-types*/
const EditDriver = ({ toolbar, ...rest }) => {
  return (
    <Edit {...rest} undoable={false} title={<MySimpleBreadcrumbs resource={rest.resource} />}>
      <SimpleForm redirect="list" toolbar={toolbar || <MyStandardEditToolbarWithList nameField="fullName" />}>
        <TextInput source="fullName" validate={validateRequired} />
        <TextInput source="personalNumber" validate={validateRequired} />
        <PasswordInput source="password" autoComplete="new-password" validate={validateRequired} />
      </SimpleForm>
    </Edit>
  );
};

EditDriver.propTypes = {
  toolbar: PropTypes.any,
};

export default EditDriver;
