import React from "react";
import { TextField, useTranslate } from "react-admin";

const RoleTextField = ({ record, ...rest }) => {
  const translate = useTranslate();

  const newRecord = { ...record };

  if (record) {
    newRecord.role = translate(`roles.${record.role}`);
  }

  return <TextField {...rest} record={newRecord} />;
};

export default RoleTextField;
