import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../Providers/Auth/authProvider.jsx";

const authProvider = (auth, history) => ({
  login: () => {
    return Promise.resolve();
  },
  logout: () => {
    auth.signOut();
    setTimeout(() => {
      history.replace("/login");
    }, 100);
    return Promise.resolve();
  },
  checkAuth: async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user.token) {
      return Promise.reject("Token is null");
    } else {
      try {
        const userCheck = await fetch(`/api/users/${user.user.id}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        if (!userCheck.ok) {
          return Promise.reject(userCheck);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    }
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const string = localStorage.getItem("user");
    if (string) {
      return Promise.resolve(JSON.parse(string));
    } else {
      return Promise.reject();
    }
  },
});

const useAuthProvider = () => {
  const auth = useAuthContext();
  const history = useHistory();

  return authProvider(auth, history);
};

export default useAuthProvider;
