import React from "react";
import { Filter, SearchInput } from "react-admin";

const ListTextFilter = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="search" alwaysOn />
    </Filter>
  );
};

export default ListTextFilter;
