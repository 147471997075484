import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useRegistrationContext } from "./registrationProvider.jsx";

const RegistrationProtectedRoute = ({ children, ...rest }) => {
  const { registrationData } = useRegistrationContext();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        registrationData ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/registration/role",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default RegistrationProtectedRoute;
