import React, { createContext, useContext, useState } from "react";
import MySnackbar from "../../SharedComponents/MySnackbar.jsx";

/**
 * @typedef {Object} SnackbarProviderContext
 * @property {FnStringVoid} error
 * @property {FnStringVoid} warn
 * @property {FnStringVoid} info
 * @property {FnStringVoid} success
 */

/** @type {React.Context<SnackbarProviderContext>} */
const snackbarContext = createContext({
  /** @type {FnStringVoid} */
  error: () => {},
  /** @type {FnStringVoid} */
  warn: () => {},
  /** @type {FnStringVoid} */
  info: () => {},
  /** @type {FnStringVoid} */
  success: () => {},
});

export const useSnackbar = () => useContext(snackbarContext);

const SnackbarProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const open = (type, msg) => {
    setAlertSeverity(type || "info");
    setMessage(msg);
    setOpen(true);
  };

  /** @type {FnStringVoid} */
  const error = (msg) => {
    open("error", msg);
  };

  /** @type {FnStringVoid} */
  const warn = (msg) => {
    open("warning", msg);
  };

  /** @type {FnStringVoid} */
  const info = (msg) => {
    open("info", msg);
  };

  /** @type {FnStringVoid} */
  const success = (msg) => {
    open("success", msg);
  };

  const close = () => {
    setOpen(false);
    setTimeout(() => {
      setAlertSeverity("info");
      setMessage("");
    }, 300);
  };

  /** @type {SnackbarProviderContext} */
  const value = {
    error,
    warn,
    info,
    success,
  };

  return (
    <snackbarContext.Provider value={value}>
      {children}
      <MySnackbar close={close} isOpen={isOpen} message={message} severity={alertSeverity} />
    </snackbarContext.Provider>
  );
};

export default SnackbarProvider;
