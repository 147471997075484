import React from "react";
import { Layout } from "react-admin";
import MyMenu from "./MyMenu.jsx";
import MySidebar from "./MySidebar.jsx";
import MyAppbar from "./MyAppbar.jsx";

const MyLayout = (props) => {
  return <Layout {...props} menu={MyMenu} sidebar={MySidebar} appBar={MyAppbar} />;
};

export default MyLayout;
