import React from "react";
import { usePermissions, useTranslate } from "react-admin";
import { Redirect } from "react-router-dom";

const NotFound = () => {
  const t = useTranslate();
  const perms = usePermissions();
  /** @type Permissions */
  const permissions = perms.permissions;

  return (
    <>
      {permissions && !permissions.driver && (
        <h1 style={{ textAlign: "center", color: "rgba(0,0,0,0.5" }}>{t("errors.routeNotFound")}</h1>
      )}
      {permissions && permissions.driver && <Redirect to="/driver-profile" />}
    </>
  );
};

export default NotFound;
