import { fetchUtils, HttpError } from "react-admin";
import lodash from "lodash";
import * as querystring from "query-string";
import { API_URL } from "../../../Constants.js";

function catchApi(e) {
  if (e.body) {
    const detail = e.body.detail ? `(${e.body.detail})` : "";
    throw new HttpError(`${e.body.title} ${detail}`, e.status);
  } else {
    throw e;
  }
}

function httpClient(url, options = {}) {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = lodash.get(JSON.parse(localStorage.getItem("user")), "token");
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  return fetchUtils.fetchJson(url, options);
}

const dataProvider = {
  getList: async (resource, params = {}) => {
    const { pagination, sort, filter } = params;
    let { field, order } = sort;
    //const customFilter = { ...filter };
    //if (pagination) {
    //  customFilter.page = pagination.page - 1;
    //  customFilter.limit = pagination.perPage;
    //}
    if (field === "index") {
      field = "id";
    }

    const query = {
      page: pagination.page - 1,
      limit: pagination.perPage,
      sort: field,
      order: order,
      filters: JSON.stringify(fetchUtils.flattenObject(filter)),
    };

    const isFiltersEmpty = lodash.isEmpty(filter) && lodash.isEmpty(sort) && lodash.isEmpty(pagination);

    const url = isFiltersEmpty ? `${API_URL}/${resource}` : `${API_URL}/${resource}?${querystring.stringify(query)}`;

    try {
      const response = await httpClient(url);
      const { json } = response;
      const { items, count, ...rest } = json;
      items.forEach((item, i) => {
        if (field === "id" && order === "DESC") {
          item.index = items.length - i;
        } else {
          item.index = i + 1;
        }
      });
      const result = {
        data: items,
        total: count,
      };
      result.data = result.data.map((item) => ({ ...item, ...rest }));
      return result;
    } catch (e) {
      catchApi(e);
    }
  },
  getOne: async (resource, params) => {
    let url = `${API_URL}/${resource}/${params.id}`;

    if (resource === "driver-profile") {
      /** @type {Permissions} */
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.driver) {
        url = `${API_URL}/drivers/${user.driver.id}`;
      }
    }

    try {
      const response = await httpClient(url);
      return {
        data: response.json,
      };
    } catch (e) {
      if (resource === "driver-profile") {
        catchApi({
          body: {
            title: "Error",
            detail: e.message,
          },
          status: 401, // to make react-admin redirect to login screen. Otherwise getting request will loop
        });
      }
      catchApi(e);
    }
  },
  getMany: async (resource, params) => {
    const query = { ids: JSON.stringify(params.ids) };
    const url = `${API_URL}/${resource}?${querystring.stringify(query)}`;

    try {
      const response = await httpClient(url);
      return {
        data: response.json.items,
      };
    } catch (e) {
      catchApi(e);
    }
  },
  getManyReference: async (resource, params) => {
    const { pagination, sort, filter, target, id } = params;
    const { page, perPage } = pagination;
    const { field, order } = sort;

    if (target && id) {
      filter.target = target;
      filter.targetId = id;
    }

    const query = {
      start: (page - 1) * perPage,
      end: page * perPage - 1,
      sort: field,
      order: order,
      filter: JSON.stringify(fetchUtils.flattenObject(filter)),
    };

    const url = `${API_URL}/${resource}?${querystring.stringify(query)}`;

    try {
      const response = await httpClient(url);
      const { json } = response;
      return {
        data: json,
        total: json.length,
      };
    } catch (e) {
      catchApi(e);
    }
  },
  create: async (resource, params) => {
    try {
      const url = `${API_URL}/${resource}`;
      const options = {
        method: "POST",
        body: JSON.stringify(params.data),
      };

      const response = await httpClient(url, options);
      return {
        data: response.json,
      };
    } catch (e) {
      catchApi(e);
    }
  },
  update: async (resource, params) => {
    let url = `${API_URL}/${resource}/${params.id}`;

    if (resource === "driver-profile") {
      url = `${API_URL}/drivers/${params.id}`;
    }

    try {
      const options = {
        method: "PUT",
        body: JSON.stringify(params.data),
      };

      const response = await httpClient(url, options);
      return {
        data: response.json,
      };
    } catch (e) {
      catchApi(e);
    }
  },
  updateMany: async () => {
    return {};
  },
  delete: async (resource, params) => {
    const { id, query } = params;

    const url = `${API_URL}/${resource}/${id}` + (query ? `?${querystring.stringify(query)}` : "");
    const options = {
      method: "DELETE",
    };

    try {
      const response = await httpClient(url, options);
      return {
        data: response.json,
      };
    } catch (e) {
      catchApi(e);
    }
  },
  deleteMany: async () => {
    return {};
  },
  put: async (path, data) => {
    const url = `${API_URL}/${path}`;
    const options = {
      method: "PUT",
      body: JSON.stringify(data),
    };

    try {
      const response = await httpClient(url, options);
      return {
        data: response.json,
      };
    } catch (e) {
      catchApi(e);
    }
  },
};

export default dataProvider;
