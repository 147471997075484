/**
 * Copied from react-admin sources
 */

import React, { Children, cloneElement, isValidElement, useState } from "react";
import PropTypes from "prop-types";
import { useTranslate, useGetIdentity } from "react-admin";
import { Tooltip, IconButton, Menu, Button, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  user: {},
  userButton: {
    textTransform: "none",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const MyUserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const { loaded, identity } = useGetIdentity();
  const classes = useStyles(props);

  const { children, label, icon, logout } = props;
  if (!logout && !children) return null;
  const open = Boolean(anchorEl);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div className={classes.user}>
      {loaded && identity?.fullName ? (
        <Button
          aria-label={label && translate(label, { _: label })}
          className={classes.userButton}
          color="inherit"
          startIcon={
            identity.avatar ? <Avatar className={classes.avatar} src={identity.avatar} alt={identity.fullName} /> : icon
          }
          onClick={handleMenu}
        >
          {identity.fullName}
        </Button>
      ) : (
        <Tooltip title={label && translate(label, { _: label })}>
          <IconButton
            aria-label={label && translate(label, { _: label })}
            aria-owns={open ? "menu-appbar" : null}
            aria-haspopup={true}
            color="inherit"
            onClick={handleMenu}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }} // this is modification of original code
        transformOrigin={{ vertical: "top", horizontal: "right" }} // this is modification of original code
        getContentAnchorEl={null} // this is modification of original code
        open={open}
        onClose={handleClose}
      >
        {Children.map(children, (menuItem) =>
          isValidElement(menuItem)
            ? cloneElement(menuItem, {
                onClick: handleClose,
              })
            : null,
        )}
        {logout}
      </Menu>
    </div>
  );
};

MyUserMenu.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  logout: PropTypes.element,
  icon: PropTypes.node,
};

MyUserMenu.defaultProps = {
  label: "ra.auth.user_menu",
  icon: <AccountCircle />,
};

export default MyUserMenu;
