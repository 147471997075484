import { useState } from "react";
import { useSnackbar } from "../Providers/Common/snackbarProvider.jsx";
import useEffectAsync from "./useEffectAsync.js";
import { get } from "../Utils/NetworkUtils.js";
import { API_URL } from "../Constants.js";

/**
 * @returns {Region[]}
 */
export default function () {
  const snackbar = useSnackbar();
  const [regions, setRegions] = useState(/** @type Region[] */ []);

  useEffectAsync(async () => {
    try {
      const resp = await get(`${API_URL}/regions`);
      if (resp.data) {
        setRegions(resp.data);
      } else {
        snackbar.error(`${resp.error.code}: ${resp.error.text}`);
      }
    } catch (e) {
      snackbar.error(e.toString());
    }
  }, []);

  return regions;
}
