import React from "react";
import TextDateField from "./TextDateField.jsx";

const TextCreatedField = (props) => {
  return <TextDateField {...props} />;
};

TextCreatedField.defaultProps = {
  addLabel: true,
  source: "createdAt",
  label: "common.created",
};

export default TextCreatedField;
