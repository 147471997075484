import React from "react";
import { Toolbar, SaveButton } from "react-admin";

/*eslint-disable react/prop-types*/
const ToolbarWithoutDeleteButton = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  );
};

export default ToolbarWithoutDeleteButton;
